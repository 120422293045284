<template>
<!--  会员医院待清洗-->
<div class="hospitalCleaning">
  <a-spin :indicator="indicator" :spinning="spinning">
    <div class="hospital_table">
      <a-button type="primary"  @click="refresh">刷新</a-button>
      <a-table :columns="columns" style="min-height: 520px" :data-source="InfoTable" :rowKey="(record,index) => {return index}"   :pagination="false"
      >
        <span slot="input" slot-scope="text,item">
          <a-input style="width: 160px" v-model="item.nameEdit" placeholder="请输入"></a-input>
        </span>
          <span slot="operate" slot-scope="text,item">
            <a  style="margin-right: 10px" @click="saveOk(item.nameEdit,item.hospital_name)">保存</a>
            <a @click="blankOk(item.hospital_name)">置空</a>
        </span>
      </a-table>
<!--      <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">-->
<!--        <a-pagination v-model="pagination.current"-->
<!--                      :total="pagination.total"-->
<!--                      @change="paginationChange" />-->
<!--      </div>-->
    </div>
  </a-spin>
</div>
</template>

<script>
import {getHospitalCleans, putCleanHospital, putHospitalCleans} from "@/service/dataCleaned_api";

export default {
  name: "hospitalCleaning",
  data(){
    return{
      spinning:false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      columns:[
        {
          title: '非医院名单的注册医院',
          dataIndex: 'hospitalName',
        },
        {
          title: '注册数量',
          dataIndex: 'count',
        },
        {
          title: '名称修改为',
          scopedSlots: { customRender: 'input' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      InfoTable:[],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"会员医院待清洗")
  },
  mounted() {
    this.getHospitalClean()
  },
  methods:{
    async getHospitalClean(){
      this.spinning = true
      const Info = await getHospitalCleans()
      if (Info.code=== 0){
        this.InfoTable = Info.data.wyYs
      }else {
        this.$message.warning('接口连接出错' + Info.message)
      }
      this.spinning = false
    },
    paginationChange(e){
    },
    //保存
    async saveOk(e,name){
      if (e !== '' && e !== undefined && e !== null){
        const data = {
          hospital : e
        }
        const save =await putHospitalCleans(name,data)
        if (save.code === 0){
          await this.getHospitalClean()
          this.$message.success('保存成功')
        }
      }else {
        this.$message.error('请输入名称')
      }
    },
    //置空
    async blankOk(name){
      const save =await putCleanHospital(name)
      if (save.code === 0){
        await this.getHospitalClean()
      this.$message.success('已置空')
      }else {
        this.$message.warning('接口出现错误' + save.message)
      }
    },
    //刷新
    refresh(){
      this.getHospitalClean()
    }
  }
}
</script>

<style lang="scss" scoped>
.hospitalCleaning{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .hospital_table{
      height:100%;
      width: 98%;
      margin: 10px auto;
      padding: 15px;
      background-color: white;
  }
}
</style>
